<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div style="background-color: grey;width: 200px;height: 30px;line-height: 30px;">
      角色名称： {{roleName}}
    </div>
    <ul v-for="item in list" :key="item.id">
      <!-- 一级菜单 -->
      <li>
        <input type="checkbox" :ref="'menuf'+item.id" :checked="isChecked('menu',item)" @change="checkparentMenu(item)" :value="item.id">
        <label>{{item.name}}</label>
      </li>
      <!-- 一级菜单功能 -->
      <span style="margin-left:10px" v-for="menuf in item.functions" :key="menuf.id">
        <input type="checkbox" :ref="'funcf'+menuf.id" :checked="isChecked('func',menuf)" @change="checkparentFun(menuf)" :value="menuf.id">
        <label>{{menuf.name}}</label>
      </span>
      <li style="margin-left:10px;" v-for="ele in item.children" :key="ele.id">
      <!-- 二级菜单 -->
        <div style="background-color:rgb(238, 238, 238);padding:5px">
          <input type="checkbox" :ref="'menus'+ele.id" :checked="isChecked('menu',ele)" @change="checksonMenu(ele)" :value="ele.id">
          <label>{{ele.name}}</label>
        </div>
        <!-- 二级菜单功能 -->
        <div style="margin-left:20px;margin-top:10px;display: inline-block;background-color: rgb(245, 246, 247);" v-for="funt in ele.functions" :key="funt.id">
          <input type="checkbox" :ref="'funcs'+funt.id" :checked="isChecked('func',funt)" @change="checksonFun(funt)" :value="funt.id">
          <label>{{funt.name}}</label>
        </div>
      <!-- 三级菜单 -->
      <div v-if="ele.children && ele.children.length!==0">
        <li style="margin-left:20px;"  v-for="thirdmenu in ele.children" :key="thirdmenu.id">
          <div style="background-color:rgb(238, 238, 238);padding:5px">
            <input type="checkbox" :ref="'menus'+thirdmenu.id" :checked="isChecked('menu',thirdmenu)" @change="checksonMenu(thirdmenu)" :value="thirdmenu.id">
            <label>{{thirdmenu.name}}</label>
          </div>
          <!-- 三级菜单功能 -->
          <div style="margin-left:20px;margin-top:10px;display: inline-block;background-color: rgb(245, 246, 247);" v-for="thirdfunc in thirdmenu.functions" :key="thirdfunc.id">
            <input type="checkbox" :ref="'funcs'+thirdfunc.id" :checked="isChecked('func',thirdfunc)" @change="checksonFun(thirdfunc)" :value="thirdfunc.id">
            <label>{{thirdfunc.name}}</label>
          </div>

        </li>
      </div>
      </li>
    </ul>
    <!-- <ul class="menu-list">
			<div v-for="(menu,key) in list" :key="key">
				<li :class="menu.level == 2 ? 'level' : 'master'">
					<input type="checkbox" :checked="ifChecked(menu, menu.id, 'menu') ? 'checked' : false" :value="menu.id" :id="'menu'+menu.id" @change="menuChange" :data-id="menu.id" :data-index="key">
					<label :for="'menu'+menu.id">{{ menu.name }}</label>
				</li>
				<li v-show="menu.func.length">
					<ul class="func-list">
						<li v-for="(fun,fkey) in menu.func" :key="fkey">
							<input type="checkbox" :checked="ifChecked(funcs, fun.id, 'func') ? 'checked' : false" :value="fun.id" :id="'func'+fun.id" @change="funcChange" :data-id="fun.id">
							<label :for="'func'+fun.id">{{ fun.name }}</label>
						</li>
					</ul>
				</li>
				<div class="clear"></div>
			</div>
		</ul> -->
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      roleId: '',
      roleName: '',
      list: [],
      defaultProps: {
        children: 'SonMenu',
        label: 'name'
      },
      funcs: [],
      functionIds: [],
      menuIds: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetRole(){
      this.api.RoleList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            if (item.id == this.roleId) {
              this.roleName = item.name
            }
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    // 是否选中
    isChecked (type,item) {
      if (type == 'menu' && this.menuIds) {
        for (let i=0;i<this.menuIds.length;i++){
          if(this.menuIds[i].menu_id == item.id){
            return true
          }
        }
      } else if (type == 'func' && this.functionIds) {
        for (let i=0;i<this.functionIds.length;i++){
          if(this.functionIds[i].fun_id == item.id){
            return true
          }
        }
      }
    },
    GetMenuFun () {
      this.api.MenuToFuncAll({}).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data
          this.GetChecked()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetChecked() {
      this.api.MenuToRoleList({
        role_id: this.roleId
      }).then(res => {
        if (res.data.code == 200) {
          console.log('已选',res.data.data)
          this.functionIds = res.data.data.functions
          this.menuIds = res.data.data.menus
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/admin/role')
    },
    menuUpdate(item){
      this.api.MenuToRoleUpdate({
        role_id: +this.roleId,
        menu_id: +item
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          this.GetChecked()
          return
        }
        this.$message.error('分配失败' + res.data.msg)
      })
    },
    menuDelete(item){
      let paramId = ''
      this.menuIds.forEach(ele => {
        if (ele.menu_id ==item.id) {
          paramId = ele.id
        }
      })
      this.api.MenuToRoleDelete({
        id: +paramId
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('取消分配成功')
          this.GetChecked()
          return
        }
        this.$message.error('取消分配失败' + res.data.msg)
      })
    },
    checksonMenu(item){
      if (this.$refs['menus'+item.id].checked) {
        this.menuUpdate(item.id)
      } else {
        this.menuDelete(item)
      }
    },
    checkparentMenu(item) {
      if(this.$refs['menuf'+item.id].checked){
        this.menuUpdate(item.id)
      } else {
        this.menuDelete(item) 
      }
    },
    funcUpdate (item) {
      this.api.MenuToFuncRoleUpdate({
        fun_id: item,
        role_id: +this.roleId
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          this.GetChecked()
          return
        }
        this.$message.error('分配失败' + res.data.msg)
      })
    },
    funcDelete (item) {
      let paramId = ''
      this.functionIds.forEach(ele => {
        if (ele.fun_id ==item.id) {
          paramId = ele.id
        }
      })
      this.api.MenuToFuncRoleDelete({
        id: +paramId
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('取消分配成功')
          this.GetChecked()
          return
        }
        this.$message.error('取消分配失败' + res.data.msg)
      })
    },
    checkparentFun (item) {
      if(this.$refs['funcf'+item.id].checked){
        this.funcUpdate(item.id)
      } else {
        this.funcDelete(item) 
      }
    },
    checksonFun (item) {
      if(this.$refs['funcs'+item.id].checked){
        this.funcUpdate(item.id)
      } else {
        this.funcDelete(item) 
      }
    }
  },
  filters: {},
  mounted () {
    this.roleId = this.$route.params.id
    this.GetRole()
  },
  created () {
    this.GetMenuFun()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
li{
  margin: 10px 0;
  display: block;
}
</style>
